"use client";

import { useEffect } from "react";
import { initializeGA } from "@/app-components/tracking/google-analytics";

// Define the GoogleAnalytics functional component
export default function GoogleAnalytics() {

  useEffect(() => {
    // Ensure that Google Analytics initializes only once per application lifetime
    if (process.env.NEXT_PUBLIC_GA_ID && !window.GA_INITIALIZED) {
      initializeGA();
      window.GA_INITIALIZED = true;
    }
  }, []);

  // The component doesn't render any UI elements
  return null;
}
