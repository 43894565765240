import { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
const useBootstrapTooltips = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const bootstrap = require("bootstrap");
      const tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
      tooltipTriggerList.map((tooltipTriggerEl) => {
        return new bootstrap.Tooltip(tooltipTriggerEl);
      });
    }
  }, []);
};

export default useBootstrapTooltips;
